<template>
  <div>
    <van-sticky :offset-top="0">
      <demo-block :title="('')">
        <van-nav-bar :title="('订单列表')" :left-text="('返回')" left-arrow @click-left="this.$router.go(-1)">
          <template #right>
            <van-icon name="search" size="18" />
          </template>
        </van-nav-bar>
      </demo-block>
    </van-sticky>
    <van-tabs v-model:active="active">
      <van-tab v-for="shop in ordertype" :title="shop.title" :key="shop.id">
        <demo-block>
          <van-card v-for="i in lscurorder" :key="i.id"
            :num="i.goodscount"
            :price="i.price"
            :desc="i.desc"
            :title="i.goodstitle"
            :thumb="i.goodspic"
            >
            <template #tags>
                <van-tag plain type="danger">标签1</van-tag>
                <van-tag plain type="danger">标签2</van-tag>
            </template>
            <template #footer>
                <van-button v-if="i.ordertype === 1" size="mini">删除订单</van-button>
                <van-button size="mini">再次购买</van-button>
            </template>
          </van-card>
        </demo-block>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { Checkbox, Button, CheckboxGroup, Card, SubmitBar, Toast, Tabs, Tab, Sticky, NavBar, Icon } from 'vant';

export default {
  components: {
    [Button.name]: Button,
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [CheckboxGroup.name]: CheckboxGroup,
    [Sticky.name]: Sticky,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab
  },
  data() {
    return {
      active: 0,
      lscurorder: [],
      lsorder: [
        { id: 0, ordertype: 3, desc: "美国进口，品质保障，糖分多，等等", goodstitle: "美国伽力果（约680g/3个）", goodspic: "https://img.yzcdn.cn/public_files/2017/10/24/e5a5a02309a41f9f5def56684808d9ae.jpeg", key: "noreceive", title: "全部订单", icon: "wap-home" },
        { id: 1, ordertype: 1, desc: "就是好，美国伽力果（约680g/3个）美国伽力果（约680g/3个）美国伽力果（约680g/3个）", goodstitle: "美国伽力果（约680g/3个）", goodspic: "https://img.yzcdn.cn/public_files/2017/10/24/e5a5a02309a41f9f5def56684808d9ae.jpeg", key: "nopay", title: "全部订单", icon: "wap-home" },
        { id: 2, ordertype: 2, desc: "美国伽力果（约680g/3个）美国伽力果（约680g/3个）美国伽力果（约680g/3个）", goodstitle: "美国伽力果（约680g/3个）", goodspic: "https://img.yzcdn.cn/public_files/2017/10/24/e5a5a02309a41f9f5def56684808d9ae.jpeg", key: "nosend", title: "全部订单", icon: "wap-home" },
        { id: 3, ordertype: 3, desc: "美国伽力果（约680g/3个）美国伽力果（约680g/3个）美国伽力果（约680g/3个）", goodstitle: "美国伽力果（约680g/3个）", goodspic: "https://img.yzcdn.cn/public_files/2017/10/24/e5a5a02309a41f9f5def56684808d9ae.jpeg", key: "noreceive", title: "全部订单", icon: "wap-home" },
        { id: 4, ordertype: 4, desc: "美国伽力果（约680g/3个）美国伽力果（约680g/3个）美国伽力果（约680g/3个）美国伽力果（约680g/3个）", goodstitle: "美国伽力果（约680g/3个）", goodspic: "https://img.yzcdn.cn/public_files/2017/10/24/e5a5a02309a41f9f5def56684808d9ae.jpeg", key: "nocomment", title: "全部订单", icon: "wap-home" },
        { id: 5, ordertype: 1, desc: "美国伽力果（约680g/3个）美国伽力果（约680g/3个）美国伽力果（约680g/3个）美国伽力果（约680g/3个）", goodstitle: "美国伽力果（约680g/3个）", goodspic: "https://img.yzcdn.cn/public_files/2017/10/24/e5a5a02309a41f9f5def56684808d9ae.jpeg", key: "nopay", title: "全部订单", icon: "wap-home" }
      ],
      ordertype: [
        { id: 0, key: "all", title: "全部订单", icon: "wap-home" },
        { id: 1, key: "nopay", title: "待付款", icon: "logistics" },
        { id: 2, key: "nosend", title: "待发货", icon: "underway-o" },
        { id: 3, key: "noreceive", title: "待收货", icon: "live" },
        { id: 4, key: "nocomment", title: "待评价", icon: "live" }
      ],
      list: [
        {
          id: '1',
          name: '张三',
          tel: '13000000000',
          address: '浙江省杭州市西湖区文三路 138 号东方通信大厦 7 楼 501 室',
          isDefault: true,
        },
        {
          id: '2',
          name: '李四',
          tel: '1310000000',
          address: '浙江省杭州市拱墅区莫干山路 50 号',
        },
      ],
      disabledList: [
        {
          id: '3',
          name: '王五',
          tel: '1320000000',
          address: '浙江省杭州市滨江区江南大道 15 号',
        }
      ],
      checkedGoods: ['1', '2', '3']
    }
  },
  watch: {
    active(val) {
      console.log('获得的active发生变化：' + val);
      setTimeout(() => {
        this.GetCurOrderInfo(val);
      }, 300);
    },
  },
  computed: {
    submitBarText() {
      const count = this.checkedGoods.length;
      return '结算' + (count ? `(${count})` : '');
    },
    totalPrice() {
      return this.goods.reduce((total, item) => total + (this.checkedGoods.indexOf(item.id) !== -1 ? item.price : 0), 0);
    }
  },
  mounted() {
    var _index = 0
    console.log(this.$route.query)
    if (this.$route.query.type === null || this.$route.query.type === undefined) _index = 0
    else _index = this.$route.query.type;
    console.log(this.active)
    setTimeout(() => {
      this.active = parseInt(_index + '')
      this.GetCurOrderInfo(this.active)
      console.log('设定index结束' + _index)
    }, 300);
  },
  methods: {
    GetCurOrderInfo (ordertype) {
      this.lscurorder = []
      if (ordertype === 0) {
        this.lscurorder = this.lsorder;
        return;
      }
      console.log('不是0')
      this.lsorder.forEach(info => {
        if (info.ordertype === ordertype) this.lscurorder.push(info)
      });
    },
    onClickLink() {
      Toast('去修改地址去');
    },
    formatPrice(price) {
      return (price / 1).toFixed(2);
    },
    onSubmit() {
      Toast('点击结算');
    }
  }
};
</script>

<style lang="less">
.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}
</style>
